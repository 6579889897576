import { gql } from 'graphql-request'

export const GET_BOOK_TOP_CHARTS = gql`
  query GetBookTopCharts(
    $limitPerPage: Int
    $page: Int
    $bookType: BookType!
    $categoryId: Int
    $startDate: DateTime!
    $endDate: DateTime!
    $topChartsType: TopChartsType!
    $bookTopChartsType: BookTopChartsType!
  ) {
    bookTopCharts(
      limitPerPage: $limitPerPage
      page: $page
      bookType: $bookType
      categoryId: $categoryId
      startDate: $startDate
      endDate: $endDate
      topChartsType: $topChartsType
      bookTopChartsType: $bookTopChartsType
    ) {
      page
      total
      data {
        id
        currentRank
        previousRank
        rankChange
        viewCount
        totalViewCount
        totalLikeCount
        book {
          title
          id
          coverImgPath
          coverResizeImgPath
          penName {
            user {
              id
              profilePageSlug
              avatarImgPath
            }
            firstPenName
            id
          }
          hasSalePromotions
          buyAllChaptersPromotion {
            startDate
            endDate
            discountType
            discountPercentage
          }
          readFreePromotion {
            startedAt
            endedAt
          }
          bookBadges {
            badgeCategoryId
            badgeImageUrl
          }
          category {
            id
            name
          }
          bookType
          isLiked
          isBanned
          likeCount
          status
          deletedAt
          publishedChapterCount
          isTranslated
          isEnded
          isEpub
          isPdf
          writingType
        }
      }
    }
  }
`
