/* eslint-disable max-classes-per-file */
import { Expose, Type, Transform } from 'class-transformer'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookTagsType } from '@models/book/BookTagsType'
import { BookEnum } from '@interfaces/BookEnum'
import { BookNewChapterType } from '@models/myReading/BookNewChapterType'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { BookBuyAllChapterPromotion } from '@models/book/BookBuyAllChapterPromotion'
import { BookReadFreePromotion } from '@models/book/BookReadFreePromotion'
import { BookBadge } from '@models/book/BookBadge'

class WriterType {
  affiliation: AffiliationEnum

  status: WriterStatusEnum
}

class UserType {
  id: number

  @Type(() => WriterType)
  writer: WriterType

  displayName: string

  profilePageSlug: string

  avatarImgPath: string
}

class PenNameType {
  firstPenName: string

  @Type(() => UserType)
  user: UserType
}

export class BookType {
  @Expose() id: number

  @Expose() writer: string

  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() coverResizeImgPath: string

  @Expose() title: string

  @Expose() cover: string

  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose() isTranslated: boolean

  @Expose() likeCount: number

  @Expose() viewCount: number

  @Expose() commentCount: number

  @Expose() isEnded: boolean

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose()
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value ?? false)
  isLiked: boolean

  @Expose() newChapter: BookNewChapterType

  @Expose({ name: 'publishedChapterCount' }) chapterCount: number

  @Expose()
  status: BookStatusEnum

  @Expose()
  isBanned: boolean

  @Expose()
  deletedAt: string | null

  @Expose()
  writingType: WritingEnum

  @Expose() publishedAt: string

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean

  @Expose() hasSalePromotions?: boolean

  @Expose()
  @Type(() => BookBuyAllChapterPromotion)
  buyAllChaptersPromotion?: BookBuyAllChapterPromotion

  @Expose()
  @Type(() => BookReadFreePromotion)
  readFreePromotion?: BookReadFreePromotion

  @Expose()
  @Type(() => BookBadge)
  bookBadges?: BookBadge[]
}
