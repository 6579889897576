import { gql } from 'graphql-request'

export const GET_STORY_READER_BY_IDS = gql`
  query GetBookReaderByIds($getBookReaderIds: [Int!]!) {
    getBookReaderByIds(ids: $getBookReaderIds) {
      id
      coverImgPath
      title
      writer
      cover
      bookType
      chapterType
      writingType
      isTranslated
      likeCount
      viewCount
      shelfAddedCount
      commentCount
      lastCommentDate
      commentable
      guestCommentable
      isEpub
      isPdf
      isEnded
      isLiked
      isEbook
      intro
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      isUserHasBlockedComment
      penName {
        id
        firstPenName
        facebookLink
        twitterLink
        user {
          id
          displayName
          avatarImgPath
          profilePageSlug
          writer {
            affiliation
            status
          }
          email
          isAdmin
        }
      }
      rating {
        id
        name
      }
      tags {
        id
        name
      }
      category {
        id
        name
      }
      bookLink {
        id
        detail
        link
      }
      publishedCharacters {
        id
        name
        imgPath
        resizeImgPath
        description
        coinsTotal
        coinsToNextLevel
        characterRole {
          id
          name
        }
        characterRank {
          id
          name
          startCoins
          endCoins
          imgPath
        }
        nextRank {
          id
          name
        }
        donatable
        visible
        publishedAt
        createdAt
        updatedAt
      }
      supporters {
        id
        displayName
        username
        avatarImgPath
        profilePageSlug
      }
      bookStory {
        name
        content
      }
      publishedAt
      createdAt
      updatedAt
      dynamicLink
      facebookShareImageUrl
      twitterShareImageUrl
      requireAgeVerify
      publishedChapterCount
      totalEbookSeriesPublish
    }
  }
`

export const GET_STORY_READER = gql`
  query GetBookReader($getBookReaderId: Int!) {
    getBookReader(id: $getBookReaderId) {
      id
      coverImgPath
      title
      writer
      cover
      bookType
      chapterType
      writingType
      isTranslated
      likeCount
      viewCount
      shelfAddedCount
      commentCount
      lastCommentDate
      commentable
      guestCommentable
      isEpub
      isPdf
      isEnded
      isLiked
      isEbook
      intro
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      isUserHasBlockedComment
      hasSalePromotions
      buyAllChaptersPromotion {
        startDate
        endDate
        discountType
        discountPercentage
      }
      readFreePromotion {
        startedAt
        endedAt
      }
      bookBadges {
        badgeCategoryId
        badgeImageUrl
      }
      penName {
        id
        firstPenName
        facebookLink
        twitterLink
        user {
          id
          displayName
          avatarImgPath
          profilePageSlug
          writer {
            affiliation
            status
          }
          email
          isAdmin
        }
      }
      rating {
        id
        name
      }
      tags {
        id
        name
      }
      category {
        id
        name
      }
      bookLink {
        id
        detail
        link
      }
      publishedCharacters {
        id
        name
        imgPath
        resizeImgPath
        description
        coinsTotal
        coinsToNextLevel
        characterRole {
          id
          name
        }
        characterRank {
          id
          name
          startCoins
          endCoins
          imgPath
        }
        nextRank {
          id
          name
        }
        donatable
        visible
        publishedAt
        createdAt
        updatedAt
      }
      supporters {
        id
        displayName
        username
        avatarImgPath
        profilePageSlug
      }
      bookStory {
        name
        content
      }
      publishedAt
      createdAt
      updatedAt
      dynamicLink
      facebookShareImageUrl
      twitterShareImageUrl
      requireAgeVerify
      publishedChapterCount
      totalEbookSeriesPublish
    }
  }
`
